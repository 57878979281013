import { useMemo } from 'react';
import {
  Box,
  Button,
  RadioGroup,
  VStack,
  Radio as ChakraRadio,
  ListItem,
  UnorderedList,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const uk_based_company = 'Is your company based in the United Kingdom?';
const uk_based_company_type = 'Please confirm if you are :';
const uk_based_company_type_options = [
  'An investment professional',
  'A high net worth company, an unincorporated association, a trust',
  'Other',
];

const validationSchema = Yup.object({
  [uk_based_company]: Yup.string().oneOf(['Yes', 'No']).required(),
  [uk_based_company_type]: Yup.string()
    .oneOf(uk_based_company_type_options)
    .required()
    .when(uk_based_company, {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const UkFinancialPromotionRules = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [uk_based_company]: metadata?.[uk_based_company] || '',
      [uk_based_company_type]: metadata?.[uk_based_company_type] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={uk_based_company}
          label={t(`steps.${stepId}.${uk_based_company}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={uk_based_company}
              onChange={(value: string) => {
                resetField(uk_based_company_type);
                setValue(uk_based_company, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(uk_based_company) === 'Yes' && (
          <>
            <GroupController
              name={uk_based_company_type}
              label="Please confirm if you are :"
              isRequired={true}
              control={control}
              render={(f) => (
                <RadioGroup
                  name={uk_based_company_type}
                  onChange={(value: string) => {
                    setValue(uk_based_company_type, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={f.value}
                >
                  <VStack spacing="5" alignItems="start">
                    <Box>
                      <ChakraRadio value="An investment professional">
                        An investment professional
                      </ChakraRadio>
                      <Box color="gray.500" mt="1" ml="6">
                        <Text>An investment professional can be:</Text>
                        <UnorderedList>
                          <ListItem>An FCA- or PRA-authorised firm;</ListItem>
                          <ListItem>
                            A government, local authority (whether in the UK or
                            elsewhere) or an international organisation; or
                          </ListItem>
                          <ListItem>
                            A person that is a director, officer or employee
                            falling within any of the above, where the
                            communication is made to him in that capacity and
                            where his responsibilities when acting in that
                            capacity involve him in the carrying on of the
                            firm’s regulated activities.
                          </ListItem>
                        </UnorderedList>
                      </Box>
                    </Box>
                    <Box>
                      <ChakraRadio value="A high net worth company, an unincorporated association, a trust">
                        A high net worth company, an unincorporated association,
                        a trust
                      </ChakraRadio>
                      <Box color="gray.500" mt="1" ml="6">
                        <Text>These can be:</Text>
                        <UnorderedList>
                          <ListItem>
                            A company which has, or which is a member of the
                            same group as an undertaking which has, called-up
                            share capital or net assets of at least:
                            <UnorderedList styleType="circle">
                              <ListItem>
                                £500,000, if the company or a member of its
                                group has more than 20 members; or
                              </ListItem>
                              <ListItem>
                                £5 million in all other cases;
                              </ListItem>
                            </UnorderedList>
                          </ListItem>
                          <ListItem>
                            An unincorporated association or partnership with
                            net assets of at least £5 million;
                          </ListItem>
                          <ListItem>
                            A trust where the aggregate value of the cash and
                            investments which forms part of the trust (before
                            deducting any liabilities):
                            <UnorderedList styleType="circle">
                              <ListItem>is £10 million or more; or</ListItem>
                              <ListItem>
                                has been £10 million or more at any time during
                                the year immediately preceding the date on which
                                the financial promotion in question was first
                                made or directed; or
                              </ListItem>
                            </UnorderedList>
                          </ListItem>
                          <ListItem>
                            Any person whilst acting the capacity of a director,
                            officer or employee of any person falling within
                            paragraph any of the above where their
                            responsibilities, when acting in that capacity,
                            involve them in engaging in the investment
                            activities of the person falling within any of the
                            above.{' '}
                          </ListItem>
                        </UnorderedList>
                      </Box>
                    </Box>

                    <Box>
                      <ChakraRadio value="Other">Other</ChakraRadio>
                    </Box>
                  </VStack>
                </RadioGroup>
              )}
            />
            {watch(uk_based_company_type) === 'Other' && (
              <Box>
                <Alert status="info" mt="5">
                  <AlertIcon />
                  <Text>
                    Please note that if you do not meet one of the definitions
                    above, but you are a UK-based entity, we will not be able to
                    offer you our services at this time.{' '}
                    <b>
                      We recommend you to to contact your relationship manager
                      at Keyrock.
                    </b>
                  </Text>
                </Alert>
              </Box>
            )}
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
