import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';
import { AreYou } from '../app/components/are-you-v2';
import { UkFinancialPromotionRules } from '../app/components/uk-financial-promotion-rules';
import { CompanyAdditionalData } from '../app/components/company-additional-data';
import { IndividualAdditionalData } from '../app/components/individual-additional-data';
import { BusinessRelationship } from '../app/components/business-relationship';
import { CapacityToAct } from '../app/components/capacity-to-act';
import { AmlProgram } from '../app/components/aml-program';
import { InvestmentFunds } from '../app/components/investment-funds';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: 'disclaimer2',
    type: StepTypeEnum.text,
  },
  {
    id: 'disclaimer3',
    type: StepTypeEnum.text,
  },
  {
    id: 'disclaimer4',
    type: StepTypeEnum.text,
  },
  {
    id: 'are_you',
    type: StepTypeEnum.custom_component,
    component: AreYou,
  },
  {
    id: 'uk_financial_promotion_rules',
    type: StepTypeEnum.custom_component,
    component: UkFinancialPromotionRules,
  },
  {
    id: 'investment_funds',
    type: StepTypeEnum.custom_component,
    component: InvestmentFunds,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_additional_data',
    type: StepTypeEnum.custom_component,
    component: CompanyAdditionalData,
  },
  {
    id: 'financial_circumstances',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Company yearly revenue',
          hasHelper: true,
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.radio,
          options: [
            'Between 500,000 CHF and 1,000,000 (1M) CHF',
            'Between 1,000,000 (1M) CHF and 3,000,000 (3M) CHF',
            'Between 3,000,000 (3M) CHF and 5,000,000 (5M) CHF',
            'Between 5,000,000 (5M) CHF and 10,000,000 (10M) CHF',
            'More than 10,000,000 (10M) CHF',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Company total net assets',
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.text,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'What is the estimated amount of assets (in CHF) involved in the business relationship with Keyrock?',
          isRequired: true,
          isEnabled: true,
          addons: {
            left: 'CHF',
          },
          type: FieldTypeEnum.number,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'What is the source of the assets involved in the business relationship',
          hasHelper: true,
          isRequired: true,
          isEnabled: true,
          type: FieldTypeEnum.textarea,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
  {
    id: 'individual_additional_data',
    type: StepTypeEnum.custom_component,
    component: IndividualAdditionalData,
  },
  {
    id: 'business_relationship',
    type: StepTypeEnum.custom_component,
    component: BusinessRelationship,
  },
  {
    id: 'capacity_to_act',
    type: StepTypeEnum.custom_component,
    component: CapacityToAct,
  },
  {
    id: 'anti_money_laundering_program',
    type: StepTypeEnum.custom_component,
    component: AmlProgram,
  },
  {
    id: 'communicate_more_info',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Additional information',
          isRequired: false,
          isEnabled: true,
          type: FieldTypeEnum.textarea,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl:
        'https://keyrock-ch.onboarding.dotfile.com/assets/terms-and-conditions.pdf',
    },
  },
];
