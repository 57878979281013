import { useMemo } from 'react';
import { Box, Button, VStack, Textarea, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio, Select } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const customer_type = 'Ownership type of your company';
const customer_type_options = [
  'Private entity (not a personal asset holding vehicles)',
  'Private entity (personal asset holding vehicles)',
  'Regulated financial institution',
  'Publicly traded with 51% or more shares traded',
  'Shares are listed on a regulated market in the EEA or an equivalent regulated market outside of the EEA  or the entity is 100% subsidiary of a company that meets the aforementioned condition',
  'Government or State Owned',
  'Trusts/Foundations',
  'Other',
];
const customer_type_other = 'customer_ownership_type_other';
const company_have_nominee_shareholders =
  'Does your company have nominee shareholders and/or shares in a bearer form?';
const criminality =
  'Has your company/any of the authorised representatives / directors / ultimate beneficial owners been subject to any allegations of criminality and/or criminal convictions ?';
const pep =
  'Is any member of the senior management or associated parties a PEP or associated to a PEP ?';
const pep_name_function_jurisdictions =
  'Please provide the name of the person(s), the function(s) and the jurisdictions involved';

const validationSchema = Yup.object({
  [customer_type]: Yup.string()
    .oneOf(customer_type_options)
    .label('This field')
    .required(),
  [customer_type_other]: Yup.string().when(customer_type, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.required().label('This field'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [company_have_nominee_shareholders]: Yup.string()
    .oneOf(['Yes', 'No'])
    .label('This field')
    .required(),
  [criminality]: Yup.string()
    .oneOf(['Yes', 'No'])
    .label('This field')
    .required(),
  [pep]: Yup.string().oneOf(['Yes', 'No']).label('This field').required(),
  [pep_name_function_jurisdictions]: Yup.string().when(pep, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required().label('This field'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

export const IndividualAdditionalData = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [customer_type]: metadata?.[customer_type] || '',
      [customer_type_other]: metadata?.[customer_type_other] || '',
      [company_have_nominee_shareholders]:
        metadata?.[company_have_nominee_shareholders] || '',
      [criminality]: metadata?.[criminality] || '',
      [pep]: metadata?.[pep] || '',
      [pep_name_function_jurisdictions]:
        metadata?.[pep_name_function_jurisdictions] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={customer_type}
          label={t(`steps.${stepId}.${customer_type}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={customer_type}
              onChange={(value: string) => {
                resetField(customer_type_other);
                setValue(customer_type, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={customer_type_options}
              defaultValue={f.value}
              hasOtherOption={false}
            />
          )}
        />

        {watch(customer_type) === 'Other' && (
          <GroupController
            name={customer_type_other}
            label={t(`steps.${stepId}.${customer_type_other}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        <GroupController
          name={company_have_nominee_shareholders}
          label={t(
            `steps.${stepId}.${company_have_nominee_shareholders}.label`,
          )}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={company_have_nominee_shareholders}
              onChange={(value: string) => {
                setValue(company_have_nominee_shareholders, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        <GroupController
          name={criminality}
          label={t(`steps.${stepId}.${criminality}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={criminality}
              onChange={(value: string) => {
                setValue(criminality, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        <GroupController
          name={pep}
          label={t(`steps.${stepId}.${pep}.label`)}
          helper={t(`steps.${stepId}.${pep}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={pep}
              onChange={(value: string) => {
                resetField(pep_name_function_jurisdictions);
                setValue(pep, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(pep) === 'Yes' && (
          <GroupController
            name={pep_name_function_jurisdictions}
            label={t(
              `steps.${stepId}.${pep_name_function_jurisdictions}.label`,
            )}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
