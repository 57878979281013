import { useMemo } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  Input,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const category = 'Category';
const other_category = 'Other category';
const are_you = 'Are you';

const validationSchema = Yup.object({
  [category]: Yup.string().required().label('This'),
  [other_category]: Yup.string().when(category, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [are_you]: Yup.string().required().label('This'),
});

export const AreYou = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [category]: metadata?.[category] || '',
      [are_you]: metadata?.[are_you] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="10" alignItems="start">
        <GroupController
          name={category}
          label={t(`steps.${stepId}.${category}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <RadioGroup
              onChange={(values: string) => {
                resetField(other_category);
                setValue(category, values ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              defaultValue={f.value}
            >
              <VStack spacing="5" alignItems="start">
                <Box>
                  <Radio value="Swiss/foreign bank">Swiss/foreign bank</Radio>
                </Box>
                <Box>
                  <Radio value="Other state supervised entities">
                    Other state supervised entities
                  </Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      Securities firms, portfolio managers, asset managers, fund
                      managers, collective investment schemes, insurance
                      companies, stock exchanges, MTFs, OTFs, DLT trading
                      facilities, or other not AML-only-supervised entities.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="Institutional investors/clients with professional treasury operations">
                    Institutional investors/clients with professional treasury
                    operations
                  </Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      A client is considered to have professional treasury
                      operations if:(i) at least one qualified person with
                      financial experience is entrusted with (ii) managing the
                      assets of the client; (iii) on a permanent basis (iv)
                      within the framework of a professional cash- or treasury
                      management.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="Other">Other</Radio>
                </Box>
              </VStack>
            </RadioGroup>
          )}
        />

        {watch(category).includes('Other') && (
          <GroupController
            name={other_category}
            label={t(`steps.${stepId}.${other_category}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        <GroupController
          name={are_you}
          label={t(`steps.${stepId}.${are_you}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <RadioGroup
              onChange={(values: string) => {
                //resetField(other_category);
                setValue(are_you, values ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              defaultValue={f.value}
            >
              <VStack spacing="5" alignItems="start">
                <Box>
                  <Radio value="An operating active entity">
                    An operating active entity
                  </Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      A company with its own premises, employees and an actual
                      business activity - Please note that you will be required
                      to fill a Form K. Download a template{' '}
                      <a
                        href="https://www.vqf.ch/en/dokumente/525-vqf-dok-nr-902-11e"
                        target="_blank"
                      >
                        here
                      </a>
                      <br />
                      For detailed guidelines see the{' '}
                      <a
                        href="https://keyrock-ch.onboarding.dotfile.com/assets/Form-K-Mock-Draft.pdf"
                        target="_blank"
                      >
                        example form
                      </a>
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="A domiciliary company">
                    A domiciliary company
                  </Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      Please note that you will be required to fill a Form A.
                      Download a template{' '}
                      <a
                        href="https://www.vqf.ch/en/dokumente/170-vqf-doc-no-902-9"
                        target="_blank"
                      >
                        here
                      </a>
                      <br />
                      For detailed guidelines see the{' '}
                      <a
                        href="https://keyrock-ch.onboarding.dotfile.com/assets/Form-A-Mock-Draft.pdf"
                        target="_blank"
                      >
                        example form
                      </a>
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="An association">An association</Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      Please note that you will be required to fill a form K if
                      your entity is active or a form A if your entity is
                      domiciliary/not active.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="A trust">A trust</Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      Please note that you will be required to fill a Form T.
                      Download a template{' '}
                      <a
                        href="https://www.vqf.ch/en/dokumente/mitglieder-1/528-vqf-dok-nr-902-13e"
                        target="_blank"
                      >
                        here
                      </a>
                      <br />
                      For detailed guidelines see the{' '}
                      <a
                        href="https://keyrock-ch.onboarding.dotfile.com/assets/Form-T-Mock-Draft.pdf"
                        target="_blank"
                      >
                        example form
                      </a>
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Radio value="A foundation">A foundation</Radio>
                  <Box color="gray.500" mt="1" ml="6">
                    <Text>
                      Please note that you will be required to fill a Form S.
                      Download a template{' '}
                      <a
                        href="https://www.vqf.ch/en/dokumente/526-vqf-dok-nr-902-12e"
                        target="_blank"
                      >
                        here
                      </a>
                      <br />
                      For detailed guidelines see the{' '}
                      <a
                        href="https://keyrock-ch.onboarding.dotfile.com/assets/Form-S-Mock-Draft.pdf"
                        target="_blank"
                      >
                        example form
                      </a>
                    </Text>
                  </Box>
                </Box>
              </VStack>
            </RadioGroup>
          )}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
