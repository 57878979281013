import { useMemo } from 'react';
import { Box, Button, VStack, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const your_capacity_to_act = 'Your capacity to act with Keyrock';
const your_capacity_to_act_options = [
  'On your own behalf',
  'On behalf of your customers',
];
const entitled_to_act = 'Entitlement to act on behalf of the company';
const entitlement_explain = 'Entitlement explanation';
const signatory_rights = 'Signatory rights';
const signatory_rights_options = ['Single', 'Jointly by two', 'Other'];
const other_signatory_right = 'Other signatory right';

const validationSchema = Yup.object({
  [your_capacity_to_act]: Yup.string()
    .oneOf(your_capacity_to_act_options)
    .required()
    .label('This'),
  [entitled_to_act]: Yup.string().oneOf(['Yes', 'No']).required().label('This'),
  [entitlement_explain]: Yup.string().when(entitled_to_act, {
    is: (val: string) => val === 'No',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [signatory_rights]: Yup.string()
    .oneOf(signatory_rights_options)
    .required()
    .label('This'),
  [other_signatory_right]: Yup.string().when(signatory_rights, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.label('This').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

export const CapacityToAct = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [entitled_to_act]: metadata?.[entitled_to_act] || '',
      [entitlement_explain]: metadata?.[entitlement_explain] || '',
      [signatory_rights]: metadata?.[signatory_rights] || '',
      [other_signatory_right]: metadata?.[other_signatory_right] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={your_capacity_to_act}
          label={t(`steps.${stepId}.${your_capacity_to_act}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={your_capacity_to_act}
              onChange={(value: string) => {
                setValue(your_capacity_to_act, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={your_capacity_to_act_options}
              defaultValue={f.value}
            />
          )}
        />

        <GroupController
          name={entitled_to_act}
          label={t(`steps.${stepId}.${entitled_to_act}.label`)}
          helper={t(`steps.${stepId}.${entitled_to_act}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={entitled_to_act}
              onChange={(values: string[]) => {
                resetField(entitlement_explain);
                setValue(entitled_to_act, values ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(entitled_to_act) === 'No' && (
          <GroupController
            name={entitlement_explain}
            label={t(`steps.${stepId}.${entitlement_explain}.label`)}
            isRequired={true}
            control={control}
            render={(f) => <Input type="text" maxW="400px" {...f} />}
          />
        )}

        <GroupController
          name={signatory_rights}
          label={t(`steps.${stepId}.${signatory_rights}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={signatory_rights}
              onChange={(values: string[]) => {
                resetField(other_signatory_right);
                setValue(signatory_rights, values ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={signatory_rights_options}
              defaultValue={f.value}
            />
          )}
        />

        {watch(signatory_rights) === 'Other' && (
          <GroupController
            name={other_signatory_right}
            label={t(`steps.${stepId}.${other_signatory_right}.label`)}
            isRequired={true}
            control={control}
            render={(f) => <Input type="text" maxW="400px" {...f} />}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
