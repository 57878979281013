import { useMemo } from 'react';
import { Box, Button, VStack, Text, Alert, AlertIcon } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const is_investment_fund = 'Is your organization an investment fund';
const nb_investors = 'How many investors does the fund have';
const nb_investors_options = [
  '20 or fewer investors',
  'More than 20 investors',
];

const validationSchema = Yup.object({
  [is_investment_fund]: Yup.string().oneOf(['Yes', 'No']).required(),
  [nb_investors]: Yup.string()
    .oneOf(nb_investors_options)
    .when(is_investment_fund, {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const InvestmentFunds = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [is_investment_fund]: metadata?.[is_investment_fund] || '',
      [nb_investors]: metadata?.[nb_investors] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={is_investment_fund}
          label={t(`steps.${stepId}.${is_investment_fund}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={is_investment_fund}
              onChange={(value: string) => {
                resetField(nb_investors);
                setValue(is_investment_fund, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(is_investment_fund) === 'Yes' && (
          <>
            <GroupController
              name={nb_investors}
              label={t(`steps.${stepId}.${nb_investors}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={nb_investors}
                  onChange={(value: string) => {
                    resetField(nb_investors);
                    setValue(nb_investors, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={nb_investors_options}
                  defaultValue={f.value}
                />
              )}
            />
            {watch(nb_investors) === '20 or fewer investors' && (
              <Box>
                <Alert status="warning" mt="5">
                  <AlertIcon />
                  <Text>
                    Please note that, as per <b>Article 42(1)</b> of the VQF
                    regulations for Collective Investment Schemes, you will be
                    required to provide <b>a full list of investors</b>,
                    including their full names, address and nationalities.
                  </Text>
                </Alert>
              </Box>
            )}
            {watch(nb_investors) === 'More than 20 investors' && (
              <Box>
                <Alert status="warning" mt="5">
                  <AlertIcon />
                  <Text>
                    Please note that, as per <b>Article 42(2)</b> of the VQF
                    regulations for Collective Investments Schemes, you might be
                    required to provide <b>a full list of all investors</b>,
                    including their full names, address and nationalities,
                    depending on the adequacy of your jurisdiction's AML
                    supervision
                  </Text>
                </Alert>
              </Box>
            )}
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
