import { useMemo } from 'react';
import { Box, Button, VStack, Textarea } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GroupController,
  Radio,
  Select,
  CountrySelect,
} from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const business_activity =
  'Please select the company’s activities in the below list';
const business_activity_options = [
  'Weapons, arms industry',
  'Trader for exotic animals',
  'Regulated money transfer companies',
  'Non-commercial regulated foreign exchange dealers',
  'Charities, religious, political and other non-profit organisations',
  'Commodity trading',
  'Diamond, precious stones and metals dealers, Jewellery dealers',
  'Adult entertainment industry',
  'Art and antiques trade',
  'Casinos, lotteries and other gambling industries',
  'Agriculture and forestry',
  'Construction',
  'DLT – Cryptocurrencies',
  'Education and science',
  'Energy, water and environment',
  'Financial industry',
  'Health & Beauty',
  'Sports',
  'Manufacturing and processing',
  'IT/Media & Entertainment, IT',
  'Politics and public administration',
  'Retail and wholesale',
  'Tourism & Accommodation',
  'Transport and logistics',
  'Welfare / Charity',
  'Other Sector / Industry',
];
const business_activity_other =
  'What is the company’s main business activity/sector?';
const business_jurisdictions =
  'What is the company’s main country of business activity?';
const service_provider = 'Does your company provide services to clients?';
const customer_jurisdictions =
  'Please select the jurisdictions of your customers';

const validationSchema = Yup.object({
  [business_activity]: Yup.string()
    .oneOf(business_activity_options)
    .required()
    .label('The activity selection'),
  [business_activity_other]: Yup.string().when(business_activity, {
    is: (val: string) => val === 'Other Sector / Industry',
    then: (schema) => schema.required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [business_jurisdictions]: Yup.array().min(1).label('This').required(),
  [service_provider]: Yup.string()
    .oneOf(['Yes', 'No'])
    .required()
    .label('This'),
  [customer_jurisdictions]: Yup.array()
    .min(1)
    .label('This')
    .when(service_provider, {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const CompanyAdditionalData = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [business_activity]: metadata?.[business_activity] || '',
      [business_activity_other]: metadata?.[business_activity_other] || '',
      [business_jurisdictions]: metadata?.[business_jurisdictions] || '',
      [service_provider]: metadata?.[service_provider] || '',
      [customer_jurisdictions]: metadata?.[customer_jurisdictions] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={business_activity}
          label={t(`steps.${stepId}.${business_activity}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={business_activity}
              onChange={(value: string) => {
                resetField(business_activity_other);
                setValue(business_activity, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={business_activity_options}
              defaultValue={f.value}
              hasOtherOption={false}
            />
          )}
        />

        {watch(business_activity) === 'Other Sector / Industry' && (
          <GroupController
            name={business_activity_other}
            label={t(`steps.${stepId}.${business_activity_other}.label`)}
            helper={t(`steps.${stepId}.${business_activity_other}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        <GroupController
          name={business_jurisdictions}
          label={t(`steps.${stepId}.${business_jurisdictions}.label`)}
          helper={t(`steps.${stepId}.${business_jurisdictions}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <CountrySelect
              onChange={(value: string[]) => {
                setValue(business_jurisdictions, value ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              defaultValue={f.value}
              isMulti={true}
            />
          )}
        />

        <GroupController
          name={service_provider}
          label={t(`steps.${stepId}.${service_provider}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={service_provider}
              onChange={(value: string) => {
                resetField(customer_jurisdictions);
                setValue(service_provider, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(service_provider) === 'Yes' && (
          <GroupController
            name={customer_jurisdictions}
            label={t(`steps.${stepId}.${customer_jurisdictions}.label`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <CountrySelect
                onChange={(value: string[]) => {
                  setValue(customer_jurisdictions, value ?? [], {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                defaultValue={f.value}
                isMulti={true}
              />
            )}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
