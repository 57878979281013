import { useMemo } from 'react';
import { Box, Button, VStack, Textarea, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select, Checkbox } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const keyrock_relationship =
  'How have you been entering in a relationship with Keyrock?';
const keyrock_relationship_options = [
  "I have been contacted by Keyrock's team",
  'I solicited Keyrock',
  'I have been introduced to Keyrock via a third party',
  'Other',
];
const keyrock_relationship_other = 'keyrock_relationship_other';
const third_party_introducer =
  'If you have been introduced to Keyrock via a third party, please provide its identification data';
const purpose_of_your_business_relationship =
  'Please provide the purpose of your business relationship with Keyrock';
const purpose_of_your_business_relationship_options = [
  'OTC crypto/crypto',
  'OTC fiat/crypto',
  'OEX crypto/crypto',
  'OEX fiat/crypto',
];
const currencies =
  'Please indicate the currencies/assets that will be involved in the service provided by Keyrock';
const wallet_addresses =
  'Please provide the incoming or/and the outgoing crypto wallet address(es)';
const wallet_description = 'Please describe the type of wallet';

const validationSchema = Yup.object({
  [keyrock_relationship]: Yup.string()
    .oneOf(keyrock_relationship_options)
    .label('This field')
    .required(),
  [keyrock_relationship_other]: Yup.string().when(keyrock_relationship, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.label('This field').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [third_party_introducer]: Yup.string().when(keyrock_relationship, {
    is: (val: string) =>
      val === 'I have been introduced to Keyrock via a third party',
    then: (schema) => schema.label('This field').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [purpose_of_your_business_relationship]: Yup.array()
    .min(1)
    .label('This field'),
  [currencies]: Yup.string().label('This field').required(),
  [wallet_addresses]: Yup.string().label('This field').required(),
  [wallet_description]: Yup.string().label('This field').required(),
});

export const BusinessRelationship = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [keyrock_relationship]: metadata?.[keyrock_relationship] || '',
      [keyrock_relationship_other]:
        metadata?.[keyrock_relationship_other] || '',
      [third_party_introducer]: metadata?.[third_party_introducer] || '',
      [purpose_of_your_business_relationship]:
        metadata?.[purpose_of_your_business_relationship] || '',
      [currencies]: metadata?.[currencies] || '',
      [wallet_addresses]: metadata?.[wallet_addresses] || '',
      [wallet_description]: metadata?.[wallet_description] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={keyrock_relationship}
          label={t(`steps.${stepId}.${keyrock_relationship}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={keyrock_relationship}
              onChange={(value: string) => {
                resetField(keyrock_relationship_other);
                resetField(third_party_introducer);
                setValue(keyrock_relationship, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={keyrock_relationship_options}
              defaultValue={f.value}
              hasOtherOption={false}
            />
          )}
        />

        {watch(keyrock_relationship) === 'Other' && (
          <GroupController
            name={keyrock_relationship_other}
            label={t(`steps.${stepId}.${keyrock_relationship_other}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        {watch(keyrock_relationship) ===
          'I have been introduced to Keyrock via a third party' && (
          <GroupController
            name={third_party_introducer}
            label={t(`steps.${stepId}.${third_party_introducer}.label`)}
            helper={t(`steps.${stepId}.${third_party_introducer}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        <GroupController
          name={purpose_of_your_business_relationship}
          label={t(
            `steps.${stepId}.${purpose_of_your_business_relationship}.label`,
          )}
          helper={t(
            `steps.${stepId}.${purpose_of_your_business_relationship}.helper`,
          )}
          isRequired={false}
          control={control}
          render={(f) => (
            <Checkbox
              stepId={stepId}
              name={purpose_of_your_business_relationship}
              onChange={(values: string[]) => {
                setValue(purpose_of_your_business_relationship, values ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={purpose_of_your_business_relationship_options}
              defaultValue={f.value}
            />
          )}
        />

        <GroupController
          name={currencies}
          label={t(`steps.${stepId}.${currencies}.label`)}
          helper={t(`steps.${stepId}.${currencies}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <GroupController
          name={wallet_addresses}
          label={t(`steps.${stepId}.${wallet_addresses}.label`)}
          helper={t(`steps.${stepId}.${wallet_addresses}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <GroupController
          name={wallet_description}
          label={t(`steps.${stepId}.${wallet_description}.label`)}
          helper={t(`steps.${stepId}.${wallet_description}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
